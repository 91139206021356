/* 
*  Localy hosted fonts for studera på svenska
*/


/* Header 1 */
@font-face {
  font-family: youngserif;
  font-weight: normal;
  font-style: normal;
  src: url(YoungSerif/YoungSerif-Regular.otf);
}

/* Info text */
@font-face {
  font-family: trispace;
  font-weight: normal;
  font-style: normal;
  src: url(Trispace/Trispace-VariableFont_wdth,wght.ttf);
}

/* Headings */
@font-face {
  font-family: montserrat;
  font-weight: normal;
  font-style: normal;
  src: url(Montserrat/Montserrat-Regular.ttf);
}
@font-face {
  font-family: montserrat-medium;
  font-weight: normal;
  font-style: normal;
  src: url(Montserrat/Montserrat-Medium.ttf);
}
@font-face {
  font-family: montserrat-bold;
  font-weight: normal;
  font-style: normal;
  src: url(Montserrat/Montserrat-Bold.ttf);
}
@font-face {
  font-family: montserrat-extra-bold;
  font-weight: normal;
  font-style: normal;
  src: url(Montserrat/Montserrat-ExtraBold.ttf);
}